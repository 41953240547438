import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { roadbookActions } from '../store';

const PartialDisplay = () => {
    const currentPartialDistance = useSelector(state => state.currentPartialDistance);
    const dispatch = useDispatch();

    const clickPartialHandler = () => {
        console.log("Reset Partial Display");
        dispatch(roadbookActions.updatePartialDistance(0.0));
    };


    console.log("Draw UI >>> PartialDisplay");
    return (
        <button onClick={clickPartialHandler} className='col-span-5 text-center bg-white rounded-lg p-4'>
            <div className=" text-3xl font-bold">Partial</div>
            <div className="digits text-5xl">{currentPartialDistance.toFixed(2)}</div>
        </button>
    )
}

export default PartialDisplay;