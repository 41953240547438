import { useSelector } from "react-redux";
import { startTracker, stopTracker } from "../gps";

const TrackerIndicator = () => {
    const isTracking = useSelector(state => state.isTracking);

    const clickTrackerHandler = () => {
        const newTrackingState = !isTracking;
        if (newTrackingState) {
            startTracker();
        }
        else {
            stopTracker();
        }
    };

    return (
        <div className='text-rtext-5xl text-center'>
            <button onClick={clickTrackerHandler}>
                <div className='text-white text-center'>Trk</div>
                <div className='text-center'>{isTracking ? '🟢' : '🔴'}</div>
            </button>
        </div>
    )
}

export default TrackerIndicator;