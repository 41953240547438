import store from './store/index';
import { roadbookActions } from './store/index';

const HIGH_ACCURACY = true;
const MAX_CACHE_AGE_MILLISECOND = 5000;
const MAX_NEW_POSITION_MILLISECOND = 4000;
const trackOptions = {
  enableHighAccuracy: HIGH_ACCURACY,
  maximumAge: MAX_CACHE_AGE_MILLISECOND,
  timeout: MAX_NEW_POSITION_MILLISECOND,
};

const degreesToRadians = (degrees) => {
    return degrees * Math.PI / 180;
}
  
const distanceInKmBetweenEarthCoordinates = (lat1, lon1, lat2, lon2) => {
    var earthRadiusKm = 6371;
  
    var dLat = degreesToRadians(lat2-lat1);
    var dLon = degreesToRadians(lon2-lon1);
  
    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);
  
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return earthRadiusKm * c;
}

const gpsSuccess = (position) => {
    const state = store.getState();
    let distance = 0.0;
    const { latitude, longitude } = position.coords;
    const accuracy = position.coords.accuracy;
    console.log("Position update - lat:" + latitude + " lng:" + longitude);
    if (!state.gpsActive) {
      console.log("Position not set yet");
      store.dispatch(roadbookActions.updatePosition({latitude: latitude, longitude: longitude, accuracy: accuracy}));
      store.dispatch(roadbookActions.updateGpsActive(true));
    }
    else {
        if (state.currentLatitude === latitude && state.currentLongitude === longitude) {
            console.log("Same position");
        }
        else {
            distance = distanceInKmBetweenEarthCoordinates(state.currentLatitude, state.currentLongitude, latitude, longitude);
            console.log("Current LAT/LNG:" + state.currentLatitude + "/" + state.currentLongitude + " - new LAT/LNG:" + latitude + "/" + longitude + " - Distance:" + distance);
            store.dispatch(roadbookActions.updateFullPosition({ 
              latitude: latitude, 
              longitude: longitude, 
              accuracy: accuracy, 
              partialDistance: state.currentPartialDistance + distance,
              totalDistance: state.currentTotalDistance + distance
            }));
        }
    }
}

const gpsError = (error) => {
    store.dispatch(roadbookActions.updateGpsIindicator('🔴'));
    switch(error.code) {
      case error.PERMISSION_DENIED:
          console.log("Benutzer lehnte Standortabfrage ab.");
          break;
      case error.POSITION_UNAVAILABLE:
        console.log("Standortdaten sind nicht verfügbar.");
          break;
      case error.TIMEOUT:
        console.log("Die Standortabfrage dauerte zu lange (Time-out).");
          break;
      default:
        console.log("unbekannter Fehler.");
          break;
}};

export const startTracker = () => {
    const newWatcherId = navigator.geolocation.watchPosition(gpsSuccess, gpsError, trackOptions);
    console.log("Watcher started " + newWatcherId);
    store.dispatch(roadbookActions.updateWatcherId(newWatcherId));
};

export const stopTracker = () => {
    const state = store.getState();
    const watcherId = state.currentWatcher;
    console.log("Stop watcher " + watcherId);
    navigator.geolocation.clearWatch(watcherId);
    store.dispatch(roadbookActions.updateWatcherId(null));
};
