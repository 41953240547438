import { useDispatch } from 'react-redux';
import { roadbookActions } from '../store';
import { useSelector } from "react-redux";
import { startTracker } from "../gps";

const RoadBookControls = (props) => {
    const dispatch = useDispatch();
    const isTracking = useSelector(state => state.isTracking);

    const clickUpHandler = () => {
        console.log("Roadbook Controll Up");
        dispatch(roadbookActions.prev());
        if (!isTracking) {
            startTracker();
        }
    }

    const clickOkHandler = () => {
        console.log("Roadbook Controll OK");
        dispatch(roadbookActions.next());
        if (!isTracking) {
            startTracker();
        }
    }

    const clickDownHandler = () => {
        console.log("Roadbook Controll Down");
        dispatch(roadbookActions.next());
        if (!isTracking) {
            startTracker();
        }
    }

    console.log("Draw UI >>> RoadBookControls");
    return (<div className="grid grid-cols-4 gap-4 p-4">
        <button onClick={clickUpHandler} className="bg-sky-500 p-4 rounded-lg text-center text-5xl text-white">
            ▲
        </button>
        <button onClick={clickOkHandler} className="col-span-2 bg-lime-500 p-4 rounded-lg text-center text-5xl text-white">
            ✓
        </button>
        <button onClick={clickDownHandler} className="bg-sky-500 p-4 rounded-lg text-center text-5xl text-white">
            ▼
        </button>
    </div>)
}

export default RoadBookControls;