import { createSlice, configureStore } from '@reduxjs/toolkit';

const initialState = { 
    currentItem: 0,
    isTracking: false,
    gpsActive: false,
    gpsIndicator: '🔴',
    showGpsInfo: false,
    currentLatitude: 0.0,
    currentLongitude: 0.0,
    currentAccuracy: 0,
    currentPartialDistance: 0.0,
    currentTotalDistance: 0.0,
    currentWatcher: null, 
    items: [
        {
          id: "0",
          total: 0.0,
          partial: 0.0
        },
        {
          id: "1",
          total: 3.3,
          partial: 3.3
        },
        {
          id: "2",
          total: 6.96,
          partial: 3.66
        },
        {
          id: "3",
          total: 9.15,
          partial: 2.19
        },
        {
          id: "4",
          total: 14.9,
          partial: 5.74
        },
        {
          id: "5",
          total: 22.35,
          partial: 7.46
        },
        {
          id: "6",
          total: 26.11,
          partial: 3.75
        },
        {
          id: "7",
          total: 29.05,
          partial: 2.95
        },
        {
          id: "8",
          total: 32.22,
          partial: 3.17
        },
        {
          id: "9",
          total: 36.83,
          partial: 4.81
        },
        {
          id: "10",
          total: 38.38,
          partial: 1.55
        },
        {
          id: "11",
          total: 38.54,
          partial: 0.15
        },
        {
          id: "12",
          total: 38.72,
          partial: 0.18
        },
        {
          id: "13",
          total: 45.51,
          partial: 6.79
        },
        {
          id: "14",
          total: 45.72,
          partial: 0.21
        },
        {
          id: "15",
          total: 47.11,
          partial: 1.39
        },
        {
          id: "16",
          total: 47.23,
          partial: 0.12
        },
        {
          id: "17",
          total: 48.37,
          partial: 1.14
        },
        {
          id: "18",
          total: 50.12,
          partial: 1.75
        },
        {
          id: "19",
          total: 51.61,
          partial: 1.49
        },
        {
          id: "20",
          total: 51.79,
          partial: 0.18
        }
    ]
};

const roadbookSlice = createSlice({
    name: 'roadbook',
    initialState: initialState,
    reducers: {
        next(state) {
            state.currentItem++;
            state.currentPartialDistance = 0.0;
        },
        prev(state) {
            state.currentItem--;
            state.currentPartialDistance = 0.0;
        },
        resetTotalToWaypoint(state) {
            state.currentTotalDistance = state.items[state.currentItem].total
        },
        updateWatcherId(state, action) {
            console.log("-> Update watcher ID " + action.payload);
            let newTrackingState
            if (action.payload === null) {
                newTrackingState = false;
            }
            else {
                newTrackingState = true;
            };
                state.currentWatcher = action.payload;
                state.isTracking = newTrackingState;
        },
        updateGpsIindicator(state, action) {
            state.gpsIndicator = action.payload;
        },
        updateGpsActive(state, action) {
            state.gpsActive = action.payload
        },
        updateShowGps(state, action) {
            state.showGpsInfo = action.payload
        },
        updateLatitude(state, action) {
            state.currentLatitude = action.payload
        },
        updateLongitude(state, action) {
            state.currentLongitude = action.payload
        },
        updatePosition(state, action) {
            let gpsIndicator
            if (action.payload.accuracy <= 50) {
                gpsIndicator = '🟢';
            }
            else {
                gpsIndicator = '🟠';
            };
            state.currentLatitude = action.payload.latitude;
            state.currentLongitude = action.payload.longitude;
            state.currentAccuracy = action.payload.accuracy;
            state.gpsIndicator = gpsIndicator;
        },
        updateFullPosition(state, action) {
            let gpsIndicator
            if (action.payload.accuracy <= 50) {
                gpsIndicator = '🟢';
            }
            else {
                gpsIndicator = '🟠';
            };
            state.currentLatitude = action.payload.latitude;
            state.currentLongitude = action.payload.longitude;
            state.currentAccuracy = action.payload.accuracy;
            state.currentPartialDistance = action.payload.partialDistance;
            state.currentTotalDistance = action.payload.totalDistance;
            state.gpsIndicator = gpsIndicator;

        },
        updatePartialDistance(state, action) {
            state.currentPartialDistance = action.payload;
        },
        updateTotalDistance(state, action) {
            state.currentTotalDistance = action.payload;
        },
    }
});

const store = configureStore({
    reducer: roadbookSlice.reducer
//    reducer: { roadbook: roadbookSlice.reducer }
});

export const roadbookActions = roadbookSlice.actions;

export default store;