import React from "react";
import TotalDisplay from "./TotalDisplay";
import TrackerIndicator from "./TrackerIndicator";
import PartialDisplay from "./PartialDisplay";
import LatitudeDisplay from "./LatitudeDisplay";
import GpsIndicator from "./GpsIndicator";
import LongitudeDisplay from "./LongituteDisplay";
import AccurancyDisplay from "./AccurancyDisplay";
import { useSelector } from "react-redux";

const GpsPanel = () => {
  const showGpsInfo = useSelector(state => state.showGpsInfo);

    console.log("Draw UI >>> GpsPanel");
    return (
      <div className='grid grid-cols-12 gap-4 p-4'>
        <TotalDisplay></TotalDisplay>
        <div className="col-span-2">
          <TrackerIndicator></TrackerIndicator>
          <GpsIndicator></GpsIndicator>
        </div>
        <PartialDisplay></PartialDisplay>

        {showGpsInfo && <LatitudeDisplay></LatitudeDisplay>}
        {showGpsInfo && <AccurancyDisplay></AccurancyDisplay>}
        {showGpsInfo && <LongitudeDisplay></LongitudeDisplay>}
      </div>
    )
}

export default GpsPanel;