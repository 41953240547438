import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { roadbookActions } from '../store';

const TotalDisplay = () => {
    const currentTotalDistance = useSelector(state => state.currentTotalDistance);
    const [showResetOptions, setShowResetOptions] = React.useState(false);
    const dispatch = useDispatch();

    const clickTotalResetZeroHandler = () => {
        console.log("Reset Total Display to Zero");
        dispatch(roadbookActions.updateTotalDistance(0.0));
        setShowResetOptions(false);
    };

    const clickTotalResetWaypointHandler = () => {
        console.log("Reset Total Display to Waypoint");
        dispatch(roadbookActions.resetTotalToWaypoint());
        setShowResetOptions(false);
    };

    console.log("Draw UI >>> TotalDisplay");
    return (<div className='col-span-5'>
            {showResetOptions ? (
                <div className="grid gap-4">
                    <button onClick={() => setShowResetOptions(false)} className="bg-rose-500 p-2 rounded-lg text-center text-xl text-white w-full">Cancel</button>
                    <button onClick={clickTotalResetWaypointHandler} className="bg-rose-500 p-2 rounded-lg text-center text-xl text-white w-full">Reset to Waypoint</button>
                    <button onClick={clickTotalResetZeroHandler} className="bg-rose-500 p-2 rounded-lg text-center text-xl text-white w-full">Reset to Zero</button>
                </div>
            ) : (
                <div className="text-center bg-white rounded-lg p-4">
                    <button type="button" onClick={() => setShowResetOptions(true)}>
                        <div className="text-3xl font-bold">Total</div>
                        <div className="digits text-5xl">{currentTotalDistance.toFixed(2)}</div>
                    </button>
                </div>
            )}
        </div>)
}

export default TotalDisplay;