function RoadBookItem(props) {

    console.log("Draw UI >>> RoadBookItem");
    return (<div className="flex flex-row" id={"rbi-" + props.itemId}>
        <div className="basis-1/2 border border-black relative">
            <div className='text-5xl font-bold text-center p-4'>
                {props.total.toFixed(2)}
            </div>
            <div className='inline-block absolute left-0 bottom-0 text-3xl font-bold text-center border-t-2 border-r-2 border-black p-2'>{props.partial.toFixed(2)}</div>
            <div className='inline-block absolute bottom-0 right-0 text-1xl font-bold text-center border-t-2 border-l-2 border-black p-2'>{props.itemId}</div>
        </div>
        <div className="basis-1/2 border border-black">
            <img src={"rbi-" + (("000" + props.itemId).slice(-3)) + ".svg"} alt="Roadbook tulip" />
        </div>
    </div>)
}

export default RoadBookItem;