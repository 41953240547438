import './App.css';
import React from 'react';
import GpsPanel from './components/GpsPanel';
import RoadBookControls from './components/RoadBookControls';
import RoadBookList from './components/RoadBookList';

 function App() {

  console.log("Draw UI >>> App");
  return (
    <div className="App">
      <div>
        <div className='bg-slate-800 sticky top-0 z-50'>
          <GpsPanel></GpsPanel>
       </div>
        <div className='roadbook-list mb-auto'>
          <RoadBookList></RoadBookList>
        </div>
        <div className='sticky bottom-0 z-50 bg-slate-800'>
          <RoadBookControls></RoadBookControls>
        </div>
      </div>
    </div>
  );
}

export default App;
