import { useSelector } from "react-redux";

const LongitudeDisplay = () => {
    const currentLongitude = useSelector(state => state.currentLongitude);

    console.log("Draw UI >>> LongituteIndicator");
    return (
        <div className='col-span-5 text-1xl font-bold text-center bg-white rounded-lg p-4'>Lat: {currentLongitude.toFixed(5)}</div>
    )
}

export default LongitudeDisplay;