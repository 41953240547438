import { useSelector } from "react-redux";

const LatitudeDisplay = () => {
    const currentLatitude = useSelector(state => state.currentLatitude);

    console.log("Draw UI >>> LatitudeIndicator");
    return (
        <div className='col-span-5 text-1xl font-bold text-center bg-white rounded-lg p-4'>Lat: {currentLatitude.toFixed(5)}</div>
    )
}

export default LatitudeDisplay;