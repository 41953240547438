import { useSelector } from "react-redux";
import RoadBookItem from './RoadBookItem';

const RoadBookList = () => {
    const currItem = useSelector(state => state.currentItem);
    const roadBookItems = useSelector(state => state.items);

    console.log("Draw UI >>> RoadBookList");
    return (
        <div>
            {roadBookItems.slice(currItem).map(item => <RoadBookItem key={item.id} total={item.total} partial={item.partial} itemId={item.id}></RoadBookItem>)}
            <div className="v-spacer"></div>
        </div>
    )
}

export default RoadBookList;