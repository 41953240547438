import { useSelector } from "react-redux";

const AccurancyDisplay = () => {
    const currentAccuracy = useSelector(state => state.currentAccuracy);

    console.log("Draw UI >>> AccurancyIndicator");
    return (
        <div className='col-span-2 text-1xl font-bold text-center bg-white rounded-lg p-4'>{currentAccuracy.toFixed(0)}</div>
    )
}

export default AccurancyDisplay;